.about_ProfileImage {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.about_ProfileImage img {
    width: 16rem;
    height: 16rem;
    background-color: var(--color-option-active);
    border-radius: 8rem;
}

.about_ProfileImage div {
    color: var(--color-text);
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.about_Timeline {
    margin-left: 4rem;
    display: flex;
}

.about_Timeline_Line {
    width: 0.5rem;
    height: auto;
    background-size: 0.5rem;
}

.about_Timeline_Content {
    flex: 1;
    width: 100%;
}

.about_Timeline_Entry {
    margin-bottom: 4rem;
}

.about_Timeline_Entry h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-left: -2rem;
    margin-top: 1rem;
    position: relative;
    margin-bottom: 0;
    -webkit-text-stroke: 0.5rem var(--color-page);
    paint-order: stroke fill;
    color: var(--color-text);
    height: 4rem;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.about_Timeline_Entry h1 > div:nth-child(1) {
    z-index: 2;
}

.about_Timeline_Entry h1 > div:nth-child(2) {
    height: 100%;
    width: calc(100% - 3rem);
    position: absolute;
    left: 3rem;
}

.about_Timeline_Entry h2 {
    font-size: 2rem;
    color: var(--color-text);
    padding-left: 1rem;
    padding-top: 2rem;
    margin: 0;
}

.about_Timeline_Entry > div {
    display: flex;
    padding-right: 4.5rem;
    gap: 2rem;
    flex-wrap: nowrap;
    margin-left: 2rem;
}

.about_Timeline_Entry > div:nth-child(4) {
    margin-top: 2rem;
}

.about_Timeline_Entry p {
    font-size: 1rem;
    padding: 2rem;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
    color: var(--color-text);
    flex-grow: 1;
}

.about_Timeline_Entry img {
    width: 16rem;
    height: 16rem;
    border-radius: 1rem;
    object-fit: cover;
}

@media screen and (max-width: 960px) {
    .about_Timeline_Entry > div {
        flex-wrap: wrap;
        gap: 0;
    }
    .about_Timeline_Entry img {
        display: none;
    }
}

@media screen and (max-width: 630px) {
    .about_Timeline_Line {
        display: none;
    }
    .about_Timeline_Entry > div {
        flex-grow: 1;
        padding-left: 1rem;
        padding-right: 1rem;
        margin: auto;
    }
    .about_Timeline {
        margin-left: 0;
    }
    .about_Timeline_Entry h1 {
        margin-left: 0;
        font-size: 1.5rem;
        height: 3rem;
    }
    .about_Timeline_Entry h1 > div:nth-child(1) {
        margin-left: 1rem;
    }
    .about_Timeline_Entry h1 > div:nth-child(2) {
        left: 0;
    }
}