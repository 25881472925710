@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

* {
    -webkit-tap-highlight-color: transparent;
}

.home {
    font-family: Inter, sans-serif;
}

.home_Root {
    --page-width: 1280px;
    --interact-scale: scale(0.98);
    --color-outer: #EEEEEE;
    --color-titlebar: rgba(255, 255, 255, 0.8);
    --color-text: black;
    --color-option: rgba(180, 180, 180, 0.5);
    --color-option-active: rgb(180, 180, 180);
    --color-voption-select: rgba(0, 0, 0, 0.1);
    --color-voption-active: rgba(0, 0, 0, 0.5);
    --color-page: #f6f6f6;
    --color-page-section: #eaeaea;

    background-color: var(--color-outer);
    width: 100%;
    height: 100dvh;
    overflow: auto;
    font-size: 0;
    overflow-x: hidden;
}

.home_Root::-webkit-scrollbar {
    width: 1rem;
}

.home_Root::-webkit-scrollbar-track {
    background-color: var(--color-titlebar);
}

.home_Root::-webkit-scrollbar-thumb {
    background-color: var(--color-option);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.home_Root::-webkit-scrollbar-thumb:active {
    background-color: var(--color-option-active);
}

.home_TitleBar {
    background-color: var(--color-titlebar);
    backdrop-filter: blur(16px);
    text-align:center;
    position: sticky;
    top: 0;
    z-index: 100;
}

.home_TitleBarContent {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: var(--page-width);
}

.home_TitleBar_Title {
    padding-left: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    font-size: 2rem;
    display: inline-block;
    color: var(--color-text);
}

.home_TitleBar_Options {
    display: inline-flex;
    flex-grow: 1;
    padding-right: 4rem;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.home_TitleBar_IconButton {
    color: var(--color-text);
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 0;
    cursor: pointer;
}

.home_TitleBar_IconButton > span {
    padding: 0;
    font-size: 1.5rem;
}

.home_TitleBar_IconButton:active {
    transform: var(--interact-scale);
}

.home_TitleBar_VOptions {
    display: none;
    padding-bottom: 1rem;
    flex-basis: 100%;
}

.home_TitleBar_Option {
    color: var(--color-text);
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: var(--color-option);
    border-radius: 8rem;
    border: 0.125rem solid var(--color-option-active);
    user-select: none;
}

.home_TitleBar_Option:not(.active):active {
    transform: var(--interact-scale);
}

.home_TitleBar_Option.active {
    background-color: var(--color-option-active);
}

.home_TitleBar_VOption {
    display: block;
    text-align: left;
    text-decoration: none;
    color: var(--color-text);
    font-size: 20px;
    padding: 0.5rem;
    padding-left: 3rem;
    padding-right: 0rem;
}

.home_TitleBar_VOption:not(.active):active {
    background-color: var(--color-voption-select);
}

.home_TitleBar_VOption.active {
    font-weight: 600;
    color: var(--color-voption-active);
}

.home_TitleBar_OptionsMenu {
    padding: 8px;
    border: none;
    background-color: transparent;
    height: 48px;
    display: none;
}

.home_TitleBar_OptionsMenu path {
    animation-direction: normal;
    animation-fill-mode: both;
    animation-duration: 0.5s;
}

.home_TitleBar_OptionsMenu_Path1, .home_TitleBar_OptionsMenu_Path2 {
    transform-origin: 50% 31.25%;
}

.home_TitleBar_OptionsMenu_Path3, .home_TitleBar_OptionsMenu_Path4 {
    transform-origin: 50% 68.75%;
}

.home_TitleBar_OptionsMenu.active path {
    animation-name: menu_cross;
}

.home_TitleBar_OptionsMenu.inactive path {
    animation-name: menu_cross_rev;
}

.home_TitleBar_OptionsMenu_Path1 {
    --menu_cross_move: 18.75%;
    --menu_cross_rot: 45deg;
}

.home_TitleBar_OptionsMenu_Path2 {
    --menu_cross_move: 18.75%;
    --menu_cross_rot: -45deg;
}

.home_TitleBar_OptionsMenu_Path3 {
    --menu_cross_move: -18.75%;
    --menu_cross_rot: -45deg;
}

.home_TitleBar_OptionsMenu_Path4 {
    --menu_cross_move: -18.75%;
    --menu_cross_rot: 45deg;
}

@keyframes menu_cross {
    0%   {
        transform: translate(0, 0);
    }
    50%  {
        transform: translate(0, var(--menu_cross_move));
    }
    100% {
        transform: translate(0, var(--menu_cross_move)) rotate(var(--menu_cross_rot));
    }
}
@keyframes menu_cross_rev {
    0%   {
        transform: translate(0, var(--menu_cross_move)) rotate(var(--menu_cross_rot));
    }
    50%  {
        transform: translate(0, var(--menu_cross_move));
    }
    100% {
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 840px) {
    .home_TitleBar_OptionsMenu {
        display: inline-block;
    }
    .home_TitleBar_Option {
        display: none;
    }
    .home_TitleBar_Options {
        padding-right: 1rem;
    }
    .home_TitleBar_Title {
        padding-left: 2rem;
        font-size: 1.5rem;
    }
    .home_TitleBar_VOptions {
        display: block;
    }
}

.home_Root[darkMode=true] {
    --page-width: 1280px;
    --color-outer: #1f1f1f;
    --color-titlebar: rgba(45, 45, 45, 0.8);
    --color-text: white;
    --color-option: rgba(120, 120, 120, 0.5);
    --color-option-active: rgb(120, 120, 120);
    --color-voption-select: rgba(133, 133, 133, 0.1);
    --color-voption-active: rgba(197, 197, 197, 0.5);
    --color-page: #313131;
    --color-page-section: #2c2c2c;
}

.home_Root[darkMode=true] .home_TitleBar_OptionsMenu {
    filter: invert();
}