.project_ProjectSection {
    display: flex;
    margin-bottom: 8rem;
}

.project_ProjectSection > p {
    flex: 1;
    color: var(--color-text);
    overflow: hidden;
    transition: flex 0.5s;
    margin: 0;
    display: flex;
}

.project_ProjectSection > p > div {
    font-size: 1rem;
    margin: 0.5rem;
    padding: 2rem;
    min-width: 15rem;
    border-radius: 0.5rem;
    background-color: var(--color-page-section);
}

.project_ProjectSection_Expand {
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: none;
    color: var(--color-text);
    user-select: none;
    padding: 0;
}

.project_ProjectSection_Expand > span {
    font-size: 2rem;
    margin: 0 0.5rem;
}

.project_ProjectWindow {
    /*width: calc(var(--page-width));*/
    width: 100%;
    overflow: hidden;
    /*margin-left: calc((100% - 100vw) / 2);*/
    /*margin-left: calc(var(--page-width)/-2);*/
    margin-left: -50%;
    height: 24rem;
    background-color: var(--color-option);
    border-radius: 2rem;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    transition: flex 0.5s, background-position-x 1s;
    display: flex;
    justify-content: flex-end;
}

.project_ProjectSection_rtl .project_ProjectWindow {
    margin-left: 0;
    /*margin-right: calc((100% - 100vw) / 2);*/
    /*margin-right: calc(var(--page-width)/-2);*/
    margin-right: -50%;
    flex-direction: row-reverse;
}

/*.project_ProjectWindow:hover {*/
/*    width: calc((100vw - 100%) / 2 + 100% - 1rem);*/
/*}*/

.project_ProjectWindow_Details {
    width: calc(var(--page-width)/2);
    max-width: 50%;
    min-width: 20rem;
    height: 100%;
    backdrop-filter: blur(0.2rem) brightness(0.9);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.project_ProjectWindow_Details h1 {
    font-size: 2.5rem;
    color: white;
    font-weight: normal;
    margin: 1rem;
    margin-top: 2rem;
    text-align: center;
    user-select: none;
    text-shadow: 0 0 8px rgba(0,0,0,0.8)
}

.project_ProjectWindow_Options {
    margin-bottom: 2rem;
    display: flex;
}

.project_ProjectWindow_Options > a {
    font-size: 1rem;
    padding: 0.5rem 1.0rem;
    /*background-color: #1e6ebd;*/
    background-color: var(--accent);
    margin: 0 0.5rem;
    border-radius: 10rem;
    user-select: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
}

.project_ProjectWindow_Options > a:active {
    transform: var(--interact-scale);
}

.project_ProjectWindow_Options > a > span {
    font-size: 1rem;
}

.project_GithubSection {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    border-top: 0.25rem dotted var(--color-option);
    padding-top: 2rem;
    color: var(--color-text);
}

.project_GithubSection > div {
    font-size: 1rem;
}

@media screen and (max-width: 700px) {
    .project_ProjectSection > p {
        flex: 0;
        width: 0;
    }
    .project_ProjectSection > p > div {
        margin-left: 0;
    }
    .project_ProjectSection_rtl > p > div {
        margin-left: 0.5rem;
        margin-right: 0;
    }
    .project_ProjectWindow {
        flex: 1;
        width: 0;
        margin: 0 !important;
        border-radius: 0 2rem 2rem 0;
    }
    .project_ProjectWindow_Details {
        max-width: 100%;
    }
    .project_ProjectSection_rtl .project_ProjectWindow {
        border-radius: 2rem 0 0 2rem;
    }
    .project_ProjectSection.project_ProjectSection_viewDesc > p {
        flex: 1;
    }
    .project_ProjectSection.project_ProjectSection_viewDesc .project_ProjectWindow {
        flex: 0;
    }
    .project_ProjectSection_Expand {
        display: block;
    }
}