.pages_HomePage {
    --accent: #38a4ab;
}

.pages_AboutPage {
    --accent: #ab6038;
}

.pages_ProjectPage {
    --accent: #5538ab;
}

.pages_Page {
    width: 100%;
    max-width: var(--page-width);
    overflow: visible;
    margin: auto;
    display: block;
    /*min-height: 200%;*/
    padding-bottom: 2rem;
    background-color: var(--color-page);
}

.pages_PageTitle {
    margin: 0;
    padding-top: 3rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-text);
}

.pages_PageTitle div {
    background-color: var(--accent);
    width: 3rem;
    height: 0.4rem;
    margin-top: 0.75rem;
}

.pages_PageImagePane {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.pages_PageImagePane img {
    flex-grow: 1;
    width: 50%;
}

.pages_PageImagePane div {
    flex-grow: 1;
    width: 50%;
    color: var(--color-text);
}

.pages_PageImagePane div h1 {
    font-size: 2rem;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
}

.pages_PageImagePane div p {
    font-size: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.pages_BentoGrid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: stretch;
    max-width: 960px;
    width: 100%;
    margin: auto;
}

.pages_BentoBox {
    width: 24rem;
    height: 16rem;
    background-color: var(--color-option-active);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0.5rem;
    border-radius: 0.5rem;
    color: white;
    overflow: hidden;
    text-decoration: none;
    transition: background-size 0.4s;
}

.pages_BentoBox:hover {
    background-size: 110%;
}

.pages_BentoBox div {
    backdrop-filter: brightness(0.5) blur(0.125rem);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    user-select: none;
}

.pages_ChipRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.pages_Chip {
    text-decoration: none;
    font-size: 1rem;
    color: var(--color-text);
    padding: 0.25rem;
    background-color: var(--color-option);
    border-radius: 4rem;
    display: inline-flex;
    align-items: center;
}

.pages_Chip:active {
    transform: var(--interact-scale);
}

.pages_Chip img {
    padding-left: 0.5rem;
    height: 1.5rem;
}

.pages_Chip div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

@media screen and (max-width: 640px) {
    .pages_PageImagePane img {
        width: 100%;
    }

    .pages_PageImagePane div {
        width: 100%;
    }
}